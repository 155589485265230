import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import Wave from "../../../assets/wave.svg"
import Quote from "../../../assets/quote.svg"
import Card from "../elements/Card"
import CheckCircle from "../../../assets/heroicons-0.4.1/optimized/solid/check-circle.svg"
import ElevatorIcon from "../../../assets/elevator.svg"

const Elevator = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          mentee: prismicRoleType(data: { role: { text: { eq: "Mentee" } } }) {
            data {
              benefits {
                first {
                  text
                }
                second {
                  text
                }
                third {
                  text
                }
              }
              description {
                text
              }
              role {
                text
              }
            }
          }
          mentor: prismicRoleType(data: { role: { text: { eq: "Mentor" } } }) {
            data {
              benefits {
                first {
                  text
                }
                second {
                  text
                }
                third {
                  text
                }
              }
              description {
                text
              }
              role {
                text
              }
            }
          }
        }
      `}
      render={({ mentee, mentor }) => {
        const cards = [
          {
            type: mentee.data.role.text.toUpperCase(),
            headline: mentee.data.description.text,
            list: Object.keys(mentee.data.benefits[0]).map(
              key => mentee.data.benefits[0][key].text
            ),
            pill: "pill-secondary",
            btn: "btn-secondary ",
            link: "/mentees",
          },
          {
            type: mentor.data.role.text.toUpperCase(),
            headline: mentor.data.description.text,
            list: Object.keys(mentor.data.benefits[0]).map(
              key => mentor.data.benefits[0][key].text
            ),
            pill: "pill-primary",
            btn: "btn ",
            link: "/mentors",
          },
        ]
        return (
          <div className="overflow-x-hidden mt-12 lg:mt-24 md:pb-64 mb-5">
            <Wave className="text-brand-blue transform -ml-64 sm:ml-0 sm:scale-x-100 lg:scale-x-150 xxl:scale-x-400 " />
            <div className="bg-brand-blue  -mt-8 ">
              <div className="container-lg relative py-8  md:py-16 text-white">
                <div className="lg:px-12">
                  <div className="flex ">
                    <Quote
                      className="mr-4 -mt-12 h-full fill-current"
                      style={{ zIndex: 2 }}
                    />
                    <h2
                      className="text-2xl md:text-4xl font-bold mb-8 md:mb-12 -mt-10"
                      style={{ zIndex: 2 }}
                    >
                      When you reach the top, remember to send the elevator back
                      down for the others.
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-8 lg:gap-16 lg:px-24  mb-8 md:-mb-64">
                    {cards.map(
                      ({ type, label, headline, pill, list, btn, link }) => (
                        <div className="h-full">
                          <h3 className="text-xl font-medium mb-3">{label}</h3>
                          <Card className="relative">
                            <div class={`${pill} mr-2 inline-block mb-3`}>
                              {type}
                            </div>
                            <h4 className="text-4xl leading-tight md:text-5xl font-bold mb-5">
                              {headline}
                            </h4>
                            <ul className="mb-12">
                              {list.map(item => (
                                <li>
                                  <div className="grid grid-cols-9 mb-3">
                                    <CheckCircle
                                      className={`${
                                        type === "MENTEE"
                                          ? "text-brand-green"
                                          : "text-brand-blue"
                                      } glyph-md col-span-1`}
                                    />
                                    <p className="col-span-8 mt-1">{item}</p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                            <Link
                              to={link+"?confetti=true"}
                              className="absolute bottom-0 left-0 w-full"
                            >
                              <div className={`${btn} m-4 text-center mt-auto`}>
                                Get Started
                              </div>
                            </Link>
                          </Card>
                        </div>
                      )
                    )}
                  </div>
                  <div className="hidden md:block absolute top-0 right-0 opacity-25 -mr-64 -mt-12">
                    <ElevatorIcon style={{ height: 425, width: 425 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Elevator
