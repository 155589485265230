import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import Quote from "../../../assets/quote.svg"

const Discover = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          prismicTestimonial {
            data {
              name {
                text
              }
              profile_pic {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 780) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              quote {
                text
              }
              role {
                text
              }
            }
          }
        }
      `}
      render={({ prismicTestimonial }) => (
        <div className="container-lg py-16 md:py-24 text-gray-800">
          <div className="grid grid-cols-1 md:grid-cols-4 mb-8">
            <div>
              <Img
                fluid={
                  prismicTestimonial.data.profile_pic.localFile.childImageSharp
                    .fluid
                }
                alt={prismicTestimonial.data.name.text + "'s profile photo"}
                className="h-32 lg:h-48 w-32 lg:w-48 rounded-full bg-gray-600 mx-auto mb-5"
              />
            </div>
            <div className="col-span-3 flex items-center">
              <div className="relative">
                <Quote className="absolute top-0 h-16 -ml-12 -mt-8 text-gray-300" />
                <h2
                  className="text-2xl md:text-3xl relative mb-3 font-bold"
                  style={{ zIndex: 2 }}
                >
                  {prismicTestimonial.data.quote.text}
                </h2>
                <p className="font-medium text-lg">
                  {prismicTestimonial.data.name.text}
                </p>
                <p>{prismicTestimonial.data.role.text}</p>
              </div>
            </div>
          </div>
          {/* <div className="flex items-center justify-center">
            <Link
              className="btn w-full text-center"
              to="/mentees/success-stories"
            >
              Read More Success Stories
            </Link>
          </div> */}
        </div>
      )}
    />
  )
}

export default Discover
