import React from "react"
import { Link, graphql } from "gatsby"
import Hero from "../components/modules/Hero"
import Elevator from "../components/modules/Elevator"
import MentorShip from "../components/modules/MentorShip"
import Layout from "../components/root/Layout"
import Seo from "../components/root/Seo"
import Newsletter from "../components/elements/Newsletter"
import Discover from "../components/modules/Discover"

export default function Home({ data }) {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero
        img={
          data.prismicHomePage.data.hero_image.localFile.childImageSharp.fluid
        }
      >
        {/* <Link
          className="items-center mb-3 hidden md:flex"
          to={data.prismicHomePage.data.tag_link[0].link_url}
        >
          <div class="pill-primary mr-2">
            {data.prismicHomePage.data.tag_link[0].badge_text.text}
          </div>
          <p class="text-base mr-2 ">
            {data.prismicHomePage.data.tag_link[0].link_text.text}
          </p>
          <ArrowRight className="glyph-sm transition duration-300 ease-in-out  transform hover:scale-110" />
        </Link> */}
        <div className="md:mt-10">
          <h1 className="text-4xl md:text-5xl leading-tight font-bold text-gray-800">
            Career mentoring <br />
            in Finance,
            <br />{" "}
          </h1>
          <h1 className="text-3xl md:text-4xl leading-tight font-bold text-brand-green mb-3 md:mb-8">
            {data.prismicHomePage.data.hero_section[0].tag_line_second.text}
          </h1>
          {/* <h1 className="text-3xl md:text-4xl leading-tight font-bold text-brand-green mb-3">
          
        </h1> */}
          <p className="mb-3 md:mb-8">
            {data.prismicHomePage.data.hero_section[0].hero_description.text}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center">
            <Link to="/mentees" className="btn-secondary">
              Find a Mentor
            </Link>
            <Link to="/mentors" className="btn">
              Become a Mentor
            </Link>
          </div>
        </div>
      </Hero>
      {/* <div className="bg-white px-8 py-8 container-lg md:mx-auto rounded">
        <div className="px-5">
          <h2 className="text-3xl md:text-4xl font-bold">
            Join our newsletter.
          </h2>
          <p>
            To meet the mentors, hear our success stories and upcoming
            initiatives subscribe to our newsletter.{" "}
            <span className="font-bold">
              {" "}
              Never any spam and you can unsubscribe at any time.
            </span>
          </p>

          <Newsletter noLabel={true} onLight={true} />
        </div>
      </div> */}
      <Elevator />
      <MentorShip />
      <Discover />
      {/* <div className="container-lg py-16 md:py-24 text-gray-800">
        <h2 className="text-4xl md:text-5xl font-bold">Meet the Mentors</h2>
        <p className="mb-5 text-xl">
          Our dedicated team of mentors are waiting for you to take a step
          towards your goals.
        </p>
        <Link to="/mentors/meet" className="btn">
          Meet them now
        </Link>
      </div> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHomePage {
      data {
        tag_link {
          badge_text {
            text
          }
          link_text {
            text
          }
          link_url
        }
        hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 780) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        hero_section {
          hero_description {
            text
          }
          sign_up_text {
            text
          }
          tag_line_one {
            text
          }
          tag_line_second {
            text
          }
        }
      }
    }
  }
`
