import React from "react"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"
import AdjustmentsIcon from "../../../assets/heroicons-0.4.1/optimized/solid/adjustments.svg"
import ClipboardIcon from "../../../assets/heroicons-0.4.1/optimized/solid/clipboard-list.svg"
import CubeIcon from "../../../assets/heroicons-0.4.1/optimized/solid/cube.svg"
import Confetti from "../../../assets/confetti.svg"
import Confetti2 from "../../../assets/confetti2.svg"

const list = [ AdjustmentsIcon, ClipboardIcon, CubeIcon]
const Hero = () => (
  <StaticQuery
    query={graphql`
      {
        prismicHowItWorks {
          data {
            title {
              text
            }
            section {
              section_title {
                text
              }
              desc {
                text
              }
            }
            description {
              text
            }
            section_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 780) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
        <h2 className="text-4xl md:text-5xl font-bold mb-8 text-center">
          {data.prismicHowItWorks.data.title.text}
        </h2>
        <p className="mb-8 max-w-4xl text-center mx-auto text-xl">
        {data.prismicHowItWorks.data.description.text}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-5 md:gap-4">
          <div className="md:-mr-6 flex items-center lg:col-span-3">
            <ul>
              {data.prismicHowItWorks.data.section.map(({ desc, section_title }, index) => {
                const Icon = list[index]
                return(
                <li>
                  <div className="grid grid-cols-10 sm:grid-cols-10 md:grid-cols-11 mb-8">
                    <div className="col-span-10 sm:col-span-2  sm:col-span-1 md:col-span-2">
                      <div className="bg-brand-green p-2 w-12 h-12 flex items-center justify-center rounded mx-auto">
                        <Icon className="text-white h-full w-full" />
                      </div>
                    </div>
                    <div className="col-span-10 sm:col-span-8 md:col-span-9">
                      <h3 className="text-xl font-bold mb-2 mt-2 md:-mt-1 text-center sm:text-left">
                        {section_title.text}
                      </h3>
                      <p className="text-lg text-center sm:text-left">
                        {desc.text}
                      </p>
                    </div>
                  </div>
                </li>
              )})}
            </ul>
          </div>
          <div className="relative lg:col-span-2  hidden lg:block lg:ml-16 lg:-mr-12 mt-8 lg:mt-0 flex justify-center">
            <div className="max-w-sm">
              <Confetti className="absolute top-0 right-0 -mt-24 confetti" />
              <Confetti2
                className="absolute bottom-0 left-0 -ml-12 -mb-24 confetti"
                style={{ zIndex: 1 }}
              />

              <Img
                fluid={data.prismicHowItWorks.data.section_image.localFile.childImageSharp.fluid}
                alt="Lady explaining her point using her hands to emphasize"
                className="relative sm:-mx-2 w-full h-64 lg:h-full rounded object-cover object-top"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Hero
